import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "@material-ui/core/Button";

const TermsOfUse = () => (
  <Layout>
    <SEO title="Terms of use" />
    <h1>Terms of use</h1>
    <h2>Acknowlegdement</h2>
    <p>
      The license agreement is set between the end user and PanoRoom. Its
      purpose is to pass a license for the product to the end user.
    </p>
    <h2>Scope of license</h2>
    <p>
      The agreements grants the user single, non-transferable license. The end
      use rmay not copy (except of terms of use of App Store), resell or
      redistribute the PanoRoom application. If you sell your device to a third
      party, you must remove the application from it. You may not copy
      reverse-engineer, disassemble, attempt to derive the source code of,
      modify, or create derivative works of the Licensed Application, any
      updates, or any part thereof (except as and only to the extent that any
      foregoing restriction is prohibited by applicable law or to the extent as
      may be permitted by the licensing terms governing use of any open-sourced
      components included with the Licensed Application).
    </p>

    <h2>Termination</h2>
    <p>
      The agreement is effective until terminated by the user or Panoroom.
      User's rights under will terminate automatically if they fail to comply
      with any terms of this agreement.
    </p>

    <h2>In App Purchases</h2>
    <p>
      The user may use a feature In App Purchases, which is supported by Apple
      Inc., to gain access to additional content in the application. It is
      expected from the user to pay using Apple's infrastructure and in return
      gain access to the features described in the payment system. Currently the
      only in-app purchase is a monthly subscription, which price might vary on
      the region. User will be billed recurrently, until expressing wish to stop
      using the paid content. Such wish can be facilitated using Apple Inc.
      payment infrastructure.
    </p>

    <h2>Maintenance and Support</h2>
    <p>
      The granted license does not grant the end user right for support, which
      however might be given by licensor voluntarily. Apple Inc. is in no way
      accountable for supporting user.
    </p>

    <h2>Warranty</h2>
    <p>
      NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED
      APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY
      APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR
      PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS" AND "AS
      AVAILABLE," WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR
      HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE
      LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR
      STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR
      CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A
      PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF
      NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION OR
      ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A
      WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU
      ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
      LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE
      EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
    </p>

    <h2>Limitation of Liability</h2>

    <p>
      TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE
      FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL
      DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
      PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL
      DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY
      TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY
      OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
      ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
      CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.
    </p>

    <h2>Intellectual property rights</h2>
    <p>
      PanoRoom is the sole proprietor of all contents used in its application.
      All claims to any intellectual property associated with the application
      must be directed at PanoRoom.
    </p>

    <h2>Legal Compliance</h2>
    <p>
      You may not use or otherwise export or re-export the Licensed Application
      except as authorized by United States law and the laws of the jurisdiction
      in which the Licensed Application was obtained. In particular, but without
      limitation, the Licensed Application may not be exported or re-exported
      (a) into any U.S.-embargoed countries or (b) to anyone on the U.S.
      Treasury Department's Specially Designated Nationals List or the U.S.
      Department of Commerce Denied Persons List or Entity List. By using the
      Licensed Application, you represent and warrant that you are not located
      in any such country or on any such list. You also agree that you will not
      use these products for any purposes prohibited by United States law,
      including, without limitation, the development, design, manufacture, or
      production of nuclear, missile, or chemical or biological weapons.
    </p>

    <h2>Third party beneficiary</h2>
    <p>
      Apple Inc. is the third party benificiary of this agreement. It will have
      right to enforce this agreement against the End-User as a third party
      beneficiary thereof.
    </p>

    <div style={{ marginBottom: "1.45rem" }}>
      <a href="/">
        <Button variant="contained" color="black">
          Lear more about PanoRoom →
        </Button>
      </a>
    </div>
  </Layout>
);

export default TermsOfUse;
